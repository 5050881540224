@import '../../styles/gifts/sass/basics';
@value formatted-text from '../../components/01_atoms/FormattedText/index.module.scss';

.footer {
  border-top: 1px solid $gray-200;
  overflow: hidden;
  padding-top: 50px;

  @include media-breakpoint-up(lg) {
    padding-top: 64px;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
      flex-direction: row;
    }

    &-logo {
      max-width: 100%;

      @include media-breakpoint-up(lg) {
        max-width: 290px;
      }

      svg {
        width: 150px;

        @include media-breakpoint-up(lg) {
          width: 100%;
        }
      }
    }

    &-description {
      max-width: 100%;

      @include media-breakpoint-up(lg) {
        max-width: 486px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 586px;
      }

      p {
        color: $gray-800;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
          font-size: 22px;
          line-height: 33px;
          margin-bottom: 32px;
        }
      }
    }

    &-menu {
      display: none;
      max-width: 220px;

      @include media-breakpoint-up(xl) {
        display: block;
      }

      ul {
        list-style: none;
        text-align: right;
      }

      li {
        margin-bottom: 18px;
      }

      a {
        color: $slate-800;
        text-decoration: none;
        display: none;

        &:hover,
        &:focus {
          color: $slate-800;
          opacity: 60%;
        }

        @include media-breakpoint-up(lg) {
          display: inline;
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }

  &__cards {
    text-align: center;
    margin-top: 90px;
    width: 100%;
    margin-bottom: 16px;
    z-index: 2;
    position: relative;
    display: inline-flex;
    gap: 12px;
    justify-content: center;

    img {
      height: 24px;
      width: 38px;
    }

    @include media-breakpoint-up(lg) {
      width: 50%;
      text-align: left;
      margin-bottom: 0;
      justify-content: left;
    }
  }

  &__bottom {
    color: $gray-800;
    position: relative;

    @include media-breakpoint-down(lg) {
      padding-bottom: 50px;
    }

    &:before {
      background: $slate-100;
      content: "";
      display: block;
      left: -100vw;
      position: absolute;
      height: calc(100% - 30px);
      width: calc(100% + 200vw);
      top: 30px;
      z-index: -1;
    }

    :global(.info) {
      background: $slate-100;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: column-reverse;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }

    [data-component="formatted-text"] {
      position: relative;
      margin-bottom: -8px;
      z-index: 1;
      text-align: center;

      @include media-breakpoint-up(lg) {
        text-align: left;
        margin-top: 8px;
      }
    }

    :global(.contacts) {
      color: $gray-800;
      font-size: 12px;
      line-height: 18px;

      @include media-breakpoint-up(lg) {
        color: $gray-800;
        margin-top: 16px;
        max-width: 408px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 708px;
      }

      p:not(:first-child) {
        margin-top: 40px;
      }
    }

    :global(.text-primary) {
      color: $turquoise-1000;
    }

    :global(.menu) {
      margin-top: 16px;

      @include media-breakpoint-up(lg) {
        margin-top: 10px;
      }

      a {
        font-size: 12px;
        line-height: 18px;
        color: $turquoise-1000;
        display: block;
        margin-bottom: 20px;
        margin-left: 0;

        &:hover,
        &:focus {
          opacity: 60%;
        }

        @include media-breakpoint-up(lg) {
          display: inline-block;
          margin-left: 10px;
          margin-bottom: 0;

          &:first-child {
            margin-left: 0;
          }
        }

        @include media-breakpoint-up(xl) {
          margin-left: 20px;
        }

        @include media-breakpoint-up(xxl) {
          margin-left: 40px;
        }
      }
    }

    :global(.mobile-menu-region-switcher-wrapper) {
      color: $gray-800;
      font-size: 12px;
      line-height: 18px;
      position: relative;
      text-align: center;
      top: 22px;
      z-index: 1;

      @include media-breakpoint-up(lg) {
        text-align: right;
        top: -28px;
      }
    }

    :global(.lang-switcher) {
      display: inline-block;
      margin-left: 12px;
      position: static;

      i {
        color: $gray-800;
      }
    }

    :global(.lang-dropdown) {
      left: auto;
      margin-top: -2px;
      padding: 0 4px;

      @include media-breakpoint-up(lg) {
        right: 0;
      }
    }
  }

  :global(.social-bar) {
    margin-top: -24px;
    position: relative;
    top: -80px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: right;
      top: -16px;
      right: -8px;
    }

    a {
      margin-right: 0;
      margin-left: 18px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
