@import '../../../styles/gifts/sass/basics';
@import '../../../styles/mixins/default-picture';

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.basket-icon-with-widget {
  display: block;
  text-align: right;
  &__shopping-cart-icon {
    height: 24px;
    margin-right: 20px;
  }
}

.basket-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  width: 100%;
  border-radius: 0;
  background: $white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

  // Don't display cart-dropdown when basket-banner is visible.
  + :global(.cart-btn:hover) :global(.cart-dropdown){
    display: none;
  }

  &__message {
    justify-content: center;
    background: $white;
    margin-top: 30px;
    padding: 17px 20px 0;
    color: $green-600;
    font-size: $font-size-small;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  &__checked-icon-success {
    margin-right: 10px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 11px;
      height: 6px;
      background: rgba(0, 0, 0, 0);
      top: 5px;
      left: 3px;
      border: 2px solid white;
      border-top: none;
      border-right: none;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    &::before {
      content: '';
      display: inline-block;
      height: 18px;
      width: 18px;
      background-color: $green-600;
      vertical-align: sub;
      border-radius: 50%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;

    :global {
      @include default-picture();
    }
  }

  &__products {
    display: none;
  }

  &__total {
    display: none;
    justify-content: space-between;
    font-size: $font-size-small;
    margin-top: -5px;
    margin-bottom: 10px;
  }

  &__go-to-basket,
  &__browse-more-gifts {
    text-align: center;
    font-size: $font-size-small;
    font-weight: bold;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  &__browse-more-gifts {
    &:hover,
    &:focus {
      color: $green-800;
      text-decoration: underline;
    }
  }

  &__close {
    position: absolute;
    top: 0px;
    right: 0px;
    font-weight: bold;
    font-size: 20px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $green-600;
    opacity: 1;
    background: none;
    &:focus,
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  @include media-breakpoint-up(md) {
    width: $cart-dropdown-width;
    border-radius: $border-radius-big;
    position: absolute;
    right: -14px;
    top: 70px;
    left: auto;
    bottom: auto;

    &__total {
      display: flex;
    }
    &__products {
      display: block;
    }

    &__close {
      padding: 5px;
    }
    &__message {
      margin-top: 0;
      padding: 17px 20px;
      background-color: $turquoise-50;
      //justify-content: flex-start;
    }

    &__checked-icon-success {
      &:before {
        vertical-align: middle;;
      }
      &:after {
        top: 7px;
      }
    }
  }
}
